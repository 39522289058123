<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="mnotify-card">
            <div
              class="mnotify-card-header d-flex justify-content-between align-items-center"
            >
              <h3 class="mnotify-card-header-text">API v.1.0</h3>
            </div>
            <div class="">
              <div class="campaigne-card-inner">
                <div class="row justify-content-center">
                  <div class="col-md-8 contains-text">
                    <div class="row __main_text_">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <el-button
                              @click="createApiV1"
                              :loading="createLoading"
                              class="create_api_key"
                            >
                              Create API Key
                            </el-button>
                          </div>
                          <div class="col-md-12">
                            <Alert
                              v-if="createError"
                              :message="createError"
                              :type="type"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="__reporting__table" v-if="apiKeys.length">
                        <table class="__mnotify__table">
                          <thead class="__mnotify__table__header">
                            <tr>
                              <th
                                style="width: 40%"
                                class="__mnotify__table__header_first"
                              >
                                Key
                              </th>
                              <th style="width: 40%">Date Created</th>
                              <th style="width: 20%">Action</th>
                            </tr>
                          </thead>
                          <tbody class="__mnotify__table__body">
                            <tr
                              class="__table__with__border"
                              v-for="(j, key) in apiKeys"
                              :key="key"
                            >
                              <td
                                class="mnotify__table__first__item"
                                style="cursor: pointer"
                              >
                                {{ j.masked_key }}
                              </td>
                              <td>
                                {{ moment(j.created_at).format("llll") }}
                              </td>
                              <td>
                                <button
                                  class="delete"
                                  @click="opendModal({ message: j, key: key })"
                                >
                                  <img
                                    width="20px"
                                    height="20px"
                                    src="@/assets/__delete.svg"
                                    alt="bin icon for delete"
                                  />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-12 __api_text">
                        <h1>Getting Started</h1>
                        <h2>Sending SMS</h2>
                        <p>
                          Endpoint:
                          https://apps.mnotify.net/smsapi?key=xxxxxxxxxx&to=xxxxxxx&msg=xxxxxxxx&sender_id=xxxxx
                        </p>
                        <p>Error Codes:</p>
                        <ul>
                          <li>1000 = Message submited successful</li>
                          <li>1002 = SMS sending failed</li>
                          <li>1003 = insufficient balance</li>
                          <li>1004 = invalid API key</li>
                          <li>1005 = invalid Phone Number</li>
                          <li>
                            1006 = invalid Sender ID. Sender ID must not be more
                            than 11 Characters. Characters include white space.
                          </li>
                          <li>1007 = Message scheduled for later delivery</li>
                          <li>1008 = Empty Message</li>
                          <li>1009 = Empty from date and to date</li>
                          <li>
                            1010 = No mesages has been sent on the specified
                            dates using the specified api key
                          </li>
                          <li>1011 = Numeric Sender IDs are not allowed</li>
                          <li>
                            1012 = Sender ID is not registered. Please contact
                            our support team via senderids@mnotify.com or call
                            0541509394 for assistance
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-12 __api_text">
                        <h2>Checking Balance</h2>
                        <p>
                          Endpoint:
                          https://apps.mnotify.net/smsapi/balance?key=xxxxxxxxxx
                        </p>
                      </div>
                      <div class="col-md-12 __api_text">
                        <h2>Checking Balance Usage on API KEY</h2>
                        <p>
                          Endpoint:
                          https://apps.mnotify.net/smsapi/api_usage?key=xxxxxxxxxx&from=Y-M-D
                          H:s:i&to=Y-M-D H:s:i
                        </p>
                        <p>
                          Visit: Video Tutorial for the step by step process
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <messageModal
        ref="messageModal"
        :payload="dataPayload"
        :from="from"
        @deleteSuccess="deleteSuccess"
      />
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import store from "@/state/store.js";
  import messageModal from "../messages/messageModal.vue";
  import moment from "moment";
  export default {
    name: "api-v1",
    components: {
      messageModal,
    },
    data() {
      return {
        createLoading: false,
        createError: "",
        type: "alert-danger",
        apiKeys: [],
        from: "",
        dataPayload: {},
      };
    },
    methods: {
      deleteSuccess(data) {
        this.apiKeys.splice(this.dataPayload.selectedKey, 1);
      },
      opendModal({ message, key }) {
        this.dataPayload = {
          currentModal: "delete",
          selectedKey: key,
          selectedMessage: message,
          deleteType: "_deleteApiV1Key",
          deleteMessage: "This will remove the Api Key permanently.",
        };
        store.dispatch("uistate/addContactFormData", {
          title: "",
          type: "delete",
        });
        let element = this.$refs.messageModal.$el;
        $(element).modal("show");
      },
      createApiV1() {
        this.createLoading = true;
        this.createError = "";
        store
          .dispatch("developer/createApiV1")
          .then((data) => {
            this.getApiV1();
            this.$alert(
              `Your API key is "${data.data}" and has been automatically downloaded. Save it somewhere safe. You won't be able to see it again.`,
              "API Key",
              {
                confirmButtonText: "OK",
              }
            );
          })
          .catch((err) => (this.createError = "An error occured. Try again"))
          .finally(() => (this.createLoading = false));
      },
      async getApiV1() {
        const data = await store.dispatch("developer/getApiV1");
        this.apiKeys = await data;
      },
    },
    mounted() {
      this.getApiV1();
    },
  };
</script>

<style lang="scss" scoped>
  .delete {
    outline: none;
    border: none;
    background: transparent;
  }
  .create_api_key {
    outline: none;
    border: none;
    background: #f7921c;
    border-radius: 5px;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #fff;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #ffffff;
    padding: 0.7em 2em;
    text-transform: uppercase;
    float: right;
  }
  .__api_text {
    margin-bottom: 1.5em;
    h1 {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 15px;
      text-transform: capitalize;
      color: #575761;
      border-bottom: 4px solid #f7921c;
      padding-bottom: 0.3em;
      width: 200px;
      max-width: 100%;
      margin-bottom: 1em;
    }
    h2 {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 15px;
      text-transform: capitalize;
      color: #575761;
      padding-bottom: 0.8em;
    }
    p {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #575761;
      margin-bottom: 0px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        font-family: "Graphik Regular";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #575761;
      }
    }
  }
  .__main_text_ {
    padding-bottom: 4em;
  }

  .contains-text {
    height: 70vh;
    overflow-y: scroll;
  }
</style>
